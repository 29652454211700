import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
} from 'react-router-dom';
import DPChat from './routes/DPChat';
import Landing from './routes/Landing';
import Loading from './routes/Loading';
import Feedback from './routes/Feedback';
import ContentCardTest from './routes/ContentCardTest';

// hash router can be used in envs where always loading index.html can be tricky
let Router;
if (process.env.BROWSER_ROUTER === true) Router = BrowserRouter;
else Router = HashRouter;

const App = () => (
  <Router>
    <Switch>
      <Route path="/loading">
        <Loading />
      </Route>
      <Route path="/video">
        <DPChat />
      </Route>
      <Route path="/feedback">
        <Feedback />
      </Route>
      <Route path="/content-card-test">
        <ContentCardTest />
      </Route>
      {/* / goes at the bottom */}
      <Route path="/">
        <Landing />
      </Route>
    </Switch>
  </Router>
);

export default App;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  CameraVideoFill,
  EyeFill,
  ChatDotsFill,
  PersonXFill,
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from '../components/Header';
import {
  headerHeight, landingBackgroundImage, landingBackgroundColor, primaryAcccent,
} from '../config';
import { createScene, setTOS } from '../store/sm/index';
import aria from '../img/jenny.png';

const iconSize = 28;
const Landing = ({
  className,
  dispatchAcceptTOS,
  tosAccepted,
  dispatchCreateScene,
  connected,
  loading,
}) => {
  const history = useHistory();
  useEffect(() => {
    if (connected) history.push('/video');
  }, [connected]);
  return (
    <div className={className}>
      <div className="landing-wrapper">
        <Header />
        <div className="container landing-container">
          <div className="row mb-4 d-flex flex-direction-column justify-content-center">
            <div className="dp-headshot mb-2" />
            <h2>
              Hello! I&apos;m Jenny, Calocurb&apos;s Digital Health and Wellness Coach.
            </h2>
          </div>
          <div className="row mb-4">
            <div className="col">
              <div className="mb-3">
                <CameraVideoFill size={iconSize} className="secondary-accent" />
              </div>
              Check you&apos;ve got a high-speed connection (5MB and above), and your camera
              and microphone are switched on.
            </div>
            <div className="col">
              <div className="mb-3">
                <EyeFill size={iconSize} className="secondary-accent" />
              </div>
              For a better experience, find a quiet spot with good lighting so I&apos;m able to
              see you and hear you better.
            </div>
            <div className="col">
              <div className="mb-3">
                <ChatDotsFill size={iconSize} className="secondary-accent" />
              </div>
              Talk to me about when to take Calocurb, or how it works. Remember I&apos;m
              still in training, so go easy on me!
            </div>
            <div className="col">
              <div className="mb-3">
                <PersonXFill size={iconSize} className="secondary-accent" />
              </div>
              I&apos;ll never ask for your personal details, neither do I record or store
              your experience.
            </div>
          </div>
          <div className="row mb-4">
            <div className="form-check">
              <label className="form-check-label" htmlFor="tosAccept">
                I accept the
                {' '}
                <a href="https://www.calocurb.com/" className="text-white">
                  Privacy Notice
                </a>
                {' '}
                and
                {' '}
                <a href="https://www.calocurb.com/" className="text-white">
                  EULA
                </a>
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="tosAccept"
                  checked={tosAccepted}
                  onChange={() => dispatchAcceptTOS((!tosAccepted))}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div>
              <button
                type="button"
                className="btn primary-accent text-white btn-lg action-btn"
                onClick={dispatchCreateScene}
                disabled={!tosAccepted || loading}
              >
                {
                loading
                  ? (
                    <div>
                      Loading
                      <div className="ms-2 spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )
                  : <div>Ready? Let&apos;s chat!</div>
              }
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Landing.propTypes = {
  className: PropTypes.string.isRequired,
  dispatchAcceptTOS: PropTypes.func.isRequired,
  dispatchCreateScene: PropTypes.func.isRequired,
  tosAccepted: PropTypes.bool.isRequired,
  connected: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

const StyledLanding = styled(Landing)`
  .link-button {
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0;
  }
  .landing-wrapper {
    min-height: calc(100vh);

    background: ${landingBackgroundImage ? `url(${landingBackgroundImage})` : ''} ${landingBackgroundColor ? `${landingBackgroundColor};` : ''};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center bottom;

    .landing-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      min-height: calc(100vh - ${headerHeight});
      color: #FFF;
      text-align: center;

      .action-btn {
        font-size: 1.8rem;
      }
    }
  }
  .dp-headshot {
    width: 200px;
    height: 200px;

    border-radius: 50%;
    border: 1px solid ${primaryAcccent};

    background-image: url(${aria});
    background-size: cover;
    background-position: center;
  }
`;
const mapStateToProps = ({ sm }) => ({
  tosAccepted: sm.tosAccepted,
  loading: sm.loading,
  connected: sm.connected,
});
const mapDispatchToProps = (dispatch) => ({
  dispatchAcceptTOS: (accepted) => dispatch(setTOS({ accepted })),
  dispatchCreateScene: () => dispatch(createScene()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StyledLanding);

import { createGlobalStyle } from 'styled-components';
import chroma from 'chroma-js';
import { primaryAcccent, primaryBackgroundColor } from './config';
import breakpoints from './utils/breakpoints';

const buttonSidePadding = '0.8rem';
const buttonVerticalPadding = '0.4rem';
export const secondaryAccent = '#88CF41';

export default createGlobalStyle`
  svg {
    vertical-align: -0.125em;
  }
  .btn {
    font-size: 1.2rem;
    @media (min-width: ${breakpoints.md}px) {
      font-size: 1.8rem;
      padding-top: ${buttonVerticalPadding};
      padding-bottom: ${buttonVerticalPadding};
      padding-right: ${buttonSidePadding};
      padding-left: ${buttonSidePadding};
    }
  }
  .form-control, .input-display, .captions {
    @media (min-width: ${breakpoints.md}px) {
      font-size: 1.8rem;
    }
  }
  .card {
    background-color: ${primaryBackgroundColor};
  }
  a,.link-button {
    color: ${primaryAcccent};
  }
  .primary-accent {
    color: ${primaryAcccent};
  }
  .secondary-accent {
    color: ${secondaryAccent};
  }
  .btn.primary-accent {
    background-color: ${primaryAcccent};
    border: 1px solid ${chroma(primaryAcccent).darken()};
    color: #FFF;
  }
  .btn.primary-outline-accent {
      background-color: none;
      border: 1px solid ${chroma(primaryAcccent).darken()};
      color: ${chroma(primaryAcccent).darken()};
    &:hover {
      background-color: ${primaryAcccent};
      color: #FFF;
    }
  }
`;

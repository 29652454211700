import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { sendTextMessage } from '../../store/sm/index';

const Options = ({ data, dispatchTextFromData }) => {
  const { options } = data;
  console.log(options);
  const optionsDisplay = options.map(({ label, value, icon }) => (
    <button
      type="button"
      className="btn primary-accent m-2 d-flex align-items-center justify-content-center"
      data-trigger-text={value || label}
      onClick={dispatchTextFromData}
      key={JSON.stringify({ label, value })}
    >
      {
        icon ? <img src={icon} alt="icon" width="100" height="100" style={{ background: '#FFF', borderRadius: '50%' }} /> : null
      }
      <div>
        {label}
      </div>
    </button>
  ));
  return (
    <div className="list-group">
      {optionsDisplay}
    </div>
  );
};

Options.propTypes = {
  data: PropTypes.shape({
    options: PropTypes.arrayOf([PropTypes.object]),
  }).isRequired,
  dispatchTextFromData: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  dispatchTextFromData: (e) => {
    dispatch(sendTextMessage({ text: e.currentTarget.dataset.triggerText }));
  },
});

export default connect(null, mapDispatchToProps)(Options);

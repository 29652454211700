import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import Router from './Router';
import store from './store';
import reportWebVitals from './reportWebVitals';
import GlobalStyle from './globalStyle';

const ForceHTTPS = () => {
  useEffect(() => {
    const { hostname, protocol } = document.location;

    if (hostname === 'jenny.calocurb.com' && protocol === 'http:') {
      console.warn('page is being served over HTTP—switch to HTTPS');
      window.location.href = window.location.href.replace('http:', 'https:');
    } else if (hostname === 'jenny.calocurb.com' && protocol === 'https:') {
      console.log('page is being served over HTTPS, proceeding...');
    } else {
      console.log('host is not jenny.calocurb.com, ignoring...');
    }
  }, []);

  return null;
};

ReactDOM.render(
  <React.StrictMode>
    <ForceHTTPS />
    <Provider store={store}>
      <Router />
    </Provider>
    <GlobalStyle />
    {/* globally enable react tooltips */}
    <ReactTooltip />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CaretRight } from 'react-bootstrap-icons';
import Color from 'color';
import { useDispatch } from 'react-redux';
import portionControl from '../../img/portion-control.svg';
import eatingRegimes from '../../img/eating-regimes.svg';
import reduceCravings from '../../img/reduce-cravings.svg';
import { primaryAcccent } from '../../config';
import { sendTextMessage } from '../../store/sm';

const CalocurbOptions = ({ className }) => {
  const dispatch = useDispatch();
  const handleClick = (e) => {
    const { msg } = e.currentTarget.dataset;
    dispatch(sendTextMessage({ text: msg }));
  };

  const caretSize = 24;
  const items = [
    <button
      className="calocurb-opt"
      key="portionControl"
      type="button"
      data-msg="portion control"
      onClick={handleClick}
    >
      <img src={portionControl} alt="portion control" />
      <div className="calocurb-opt-text">
        I am looking to reduce my portion sizes.
      </div>
      <div>
        <span className="calocurb-opt-arrow"><CaretRight size={caretSize} /></span>
      </div>
    </button>,
    <button
      className="calocurb-opt"
      key="eatingRegimes"
      type="button"
      data-msg="eating regimes"
      onClick={handleClick}
    >
      <img src={eatingRegimes} alt="eating regimes" />
      <div className="calocurb-opt-text">
        I want help with a specific eating plan like Intermittent Fasting, or Keto.
      </div>
      <div>
        <span className="calocurb-opt-arrow"><CaretRight size={caretSize} /></span>
      </div>
    </button>,
    <button
      className="calocurb-opt"
      key="reduceCravings"
      type="button"
      data-msg="reduce cravings"
      onClick={handleClick}
    >
      <img src={reduceCravings} alt="reduce cravings" />
      <div className="calocurb-opt-text">
        I am looking to reduce my cravings.
      </div>
      <div>
        <span className="calocurb-opt-arrow"><CaretRight size={caretSize} /></span>
      </div>
    </button>,
  ];

  return (
    <div className={className}>
      {[...items]}
    </div>
  );
};

CalocurbOptions.propTypes = {
  className: PropTypes.string.isRequired,
};

export default styled(CalocurbOptions)`

  .calocurb-opt {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 0.5rem;
    padding: 0.5rem;

    background: #FFF;
    border: 1px solid ${Color(primaryAcccent).alpha(0.2)};
    border-radius: 5px;
    color: ${primaryAcccent};

    &:hover {
      border: 1px solid ${Color(primaryAcccent).alpha(0.6)};
      color: #FFF;
      background: ${primaryAcccent};

      >img {
        filter: grayscale(1) invert(1);
      }
    }

    >img {
      height: 100px;
    }
  }

  .calocurb-opt-text {
    width: 20rem;
    padding: 1rem;
  }
`;

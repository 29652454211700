/* eslint-disable import/prefer-default-export */
import CaloLogo from './img/calocurb-logo.png';
import bg from './img/hops5-blur.png';

// header will not take up vertical height when transparent, so you need to be mindful of overlap
export const primaryBackgroundColor = '#F2F1EB';
export const primaryAcccent = '#223a33';
export const transparentHeader = true;
export const headerHeight = '4.2rem';
export const logo = CaloLogo;
export const logoAltText = 'Calocurb Logo';
export const logoLink = '/';

// background image is positioned in a way that is best for pictures of the persona's face.
// adjust spacing as necessary in Landing.js for different images
// if you want just a color, set landingBackgroundImage to null
// if desired, a gradient can also be added to landingBackgroundColor
export const landingBackgroundColor = undefined;
export const landingBackgroundImage = bg;

// if set to true, on disconnect, the app will redirect to the specified route.
// if false, it will redirect to /
export const disconnectPage = true;
export const disconnectRoute = '/feedback';
